<template>
  <div class="retailcont  retail_bg_color" :class="defineSize">
    <h3 v-if="title">{{ translations.title_retail }}</h3>
    <div class="left">
      <div class="imgcont">
        <img :src="require('@/assets/icons/retaillogo.png')" />
      </div>
    </div>
    <div class="right">
      <a :href="defineURL" target="_blank">
        <ExhibitorTool
          :tool="{ icon: 'fas fa-arrow-circle-right' }"
          :border="false"
          :size="'normal'"
          :index="-1"
          :bolder="true"
          class="tool"
        />
      </a>

      <p v-if="size == 'big'">
        {{ translations.text_retail_icon }}
      </p>
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import ExhibitorTool from '@/components/ExhibitorTool.vue'
  import { mapState } from 'vuex'
  export default {
    name: 'ExhibitorRetail',
    components: {
      Title,
      ExhibitorTool,
    },
    data() {
      return {
        priceTitles: ['Oplus price', '+ Earn Copperaplates'],
      }
    },
    props: {
      retail: {
        type: Object,
        default: {
          slider: [
            { url: 'slider1.jpg', description: 'img1' },
            { url: 'url2', description: 'img2' },
            { url: 'url3', description: 'img3' },
          ],
          text:
            'See beyond the horizon, explore the unknown, and leap boldly into the future, with unprecedented power, speed and imagination. Embrace the intelligence and live an intimate life with innovative technology on the HUAWEI Mate 40 Pro.',
        },
      },
      title: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: 'big',
      },
    },
    computed: {
      ...mapState(['files', 'urlretail', 'translations']),
      defineSize() {
        return this.size == 'medium' ? 'mediumformat' : ''
      },
      defineURL() {
        return this.urlretail.replace('{code}', this.retail.code)
      },
    },
  }
</script>

<style scoped>
  .retailcont {
    padding: 10px;
    box-shadow: 0 5px 5px;
    text-align: center;
    border-radius: 5px;
    position: relative;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .icon img {
    width: 30px;
  }
  .tilte {
    text-transform: uppercase;
  }
  .retailcont .left,
  .retailcont .right {
    display: inline-block;
    vertical-align: top;

    width: 47%;
    padding: 10px;
    /* margin-top: 20px; */
  }
  .mediumformat .left,
  .mediumformat .ight {
    width: 44%;
    height: 50px;
  }
  .imgcont {
    display: inline-block;
    width: 80%;
    position: relative;
    height: 100%;
    padding: 0 20px;
    margin-bottom: 20px;
  }
  .imgcont img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .price {
    color: #54595f;
    font-weight: bold;
    padding-right: 20px;
  }
  .left p {
    font-size: 10px;
  }
  p {
    /* text-align: left; */
    font-size: 15px;
  }
  .alignleft {
    text-align: left;
  }

  h3 {
    font-size: 18px;
    margin: 10px 0;
    font-weight: bold;
    color: #54595f;
  }
  .float {
    float: right;
  }
  .tool {
    margin: 0;
    padding: 0;
  }
  @media screen and (max-width: 1050px) {
    .retailcont .left,
    .retailcont .right {
      width: 45%;
    }
    /* .mediumformat .left {
      height: 480px;
    } */
  }
  @media screen and (max-width: 750px) {
    .mediumformat .left {
      height: 250px;
    }
    .retailcont .left {
      margin-top: 0;
    }
    .retailcont .left,
    .retailcont .right {
      width: 97%;
      padding: 0;
    }
    .left p {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 400px) {
    h3 {
      font-size: 16px;
    }
  }
</style>
