<template>
  <div class="cont" :class="defineBackground()">
    <div class="fourth_color list title">
      <span class="center">
        {{ data.title }}
      </span>
    </div>
    <div class="text list">
      <span class="center">
        {{ data.description }}
      </span>
    </div>
    <div class="toolcont list actions">
      <ExhibitorTool
        :tool="{ text: defineText(0), icon: defineIcon(0) }"
        :border="false"
        :index="0"
        :size="'small'"
        :iconChange="2"
        :noMargin="true"
        class="tool mouseHover"
        @click="addToMyBriefcase()"
        v-if="show"
      />
      <ExhibitorTool
        :tool="{ text: defineText(0), icon: defineIcon(0) }"
        :border="false"
        :noMargin="true"
        :index="0"
        :size="'small'"
        :iconChange="2"
        class="tool mouseHover"
        @click="addToMyBriefcase()"
        v-else
      />

      <!-- <a :target="defineTarget"> -->
      <ExhibitorTool
        :tool="{ text: defineText(1), icon: defineIcon(1) }"
        @click="downloadFile()"
        :border="false"
        :index="0"
        :size="'small'"
        :noMargin="true"
        :iconChange="2"
        class="tool mouseHover"
      />
      <!-- </a> -->
    </div>
  </div>
</template>

<script>
  import ExhibitorTool from '@/components/ExhibitorTool.vue'
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'SaveAndDownload',
    components: {
      ExhibitorTool,
    },
    data() {
      return {
        show: false,
      }
    },
    props: {
      index: {
        type: Number,
        default: 0,
      },
      data: {
        type: Object,
        default: {
          title: 'Product catalogue',
          description:
            'View product catalogue and learn how to start using HUAWEI phone, transfer information from your previous device.',
          options: [
            { text: 'Save download', saveDownload: 'url' },
            { text: 'Download presentation', urlDownload: 'url' },
          ],
        },
      },
      type: {
        type: String,
        default: 'download',
      },
    },
    computed: {
      ...mapState(['files', 'logged', 'myfavoritesBack']),
      defineTarget() {
        return this.logged ? '_blank' : '_self'
      },
      checkURL() {
        return this.data.url ? this.data.url : this.files + '/'
      },
    },
    methods: {
      ...mapActions(['setMessage', 'changeToolSelected', 'checkMyFavorites']),
      defineBackground() {
        return this.index % 2 ? '' : 'eighth_bg_color'
      },
      defineIcon(index) {
        let icon = ''
        if (index == 1) {
          icon = 'fas fa-file-download'
        } else if (index == 0) {
          if (this.show) {
            icon = 'fas fa-bookmark'
          } else {
            icon = 'far fa-bookmark'
          }
        }
        return icon
      },
      defineText(index) {
        let text = ''
        if (index == 0) {
          text = 'Save download'
        } else {
          text = 'Download file'
        }
        return text
      },
      async addToMyBriefcase() {
        if (this.logged) {
          var ls = localStorage.getItem('myFavorites')
          ls = ls ? JSON.parse(ls) : { items: [] }
          let find = false
          let i = 0
          let index = null
          if (ls.items.length > 0) {
            ls.items.forEach((element) => {
              if (element.code == this.data.code && element.type == this.type) {
                find = true
                index = i
              }
              i++
            })
          }
          if (!find) {
            ls.items.push({ code: this.data.code, type: this.type })
            this.show = true
          } else {
            ls.items.splice(index, 1)
            this.show = false
          }
          localStorage.setItem('myFavorites', JSON.stringify(ls))
          await this.checkMyFavorites()
        } else {
          this.setMessage({ msg: 'msg_need_to_be_logged', to: 'login' })
          this.changeToolSelected(0)
        }
      },
      downloadFile() {
        if (!this.logged) {
          this.setMessage({ msg: 'msg_need_to_be_logged', to: 'login' })
          this.changeToolSelected(0)
        } else {
          window.open(
            this.checkURL + 'download/' + this.data.filename,
            '_blank'
          )
        }
      },
      isInMyBriefcase() {
        var ls = localStorage.getItem('myFavorites')
        ls = ls ? JSON.parse(ls) : { items: [] }
        let find = false

        ls.items.forEach((element) => {
          if (element.code == this.data.code && element.type == this.type) {
            find = true
          }
        })
        this.show = find
      },
    },

    created() {
      this.isInMyBriefcase()
    },
    watch: {
      'myfavoritesBack.items'(newValue, oldValue) {
        this.isInMyBriefcase()
      },
    },
  }
</script>

<style scoped>
  h4 {
    font-size: 20px;
    margin: 5px 0;
  }
  .center {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .toolcontent {
    padding: 10px 0;
  }
  .cont {
    text-align: center;
    padding: 10px;
    height: 62px;
    overflow: hidden;
  }
  .cont:hover {
    color: #fff;
    background-color: #d0d0d0;
  }

  .tool {
    display: inline-block;
    width: 50%;
    font-size: 13px;
    height: 100%;
    vertical-align: top;
  }
  .title,
  .text {
    /* padding: 0 10px; */
    text-align: left;
  }
  .title {
    font-weight: bold;
    font-size: 17px;
  }
  .list {
    display: inline-block;
    width: 25%;

    height: 100%;
    vertical-align: middle;
  }
  .actions {
    width: 46%;
    vertical-align: top;
  }
</style>
