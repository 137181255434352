<template>
  <div class="viewcontent">
    <Title
      background="primary_bg_color"
      type="subtitle"
      :text="productData.title"
    />
    <div class="contleftright">
      <div class="left">
        <div class="imgcont">
          <Slider
            class="slider"
            :perPage="1"
            :images="productData.img"
            :indexNum="productData.img.length"
            :link="false"
            v-if="productData.img && productData.img.length > 1"
          />
          <img
            v-else-if="productData.img && productData.img.length == 1"
            :src="files + productData.img[0].url"
            :alt="productData.img[0].description"
            class="brandimg"
          />
        </div>
        <div v-if="productData.productText" class="descriptioncont">
          <p
            v-for="(text, index) in productData.productText.split(
              /<br\s*\/?>|\n/g
            )"
            :key="index"
            class="description"
          >
            {{ text }}
          </p>
        </div>
        <div
          v-if="
            productData.code && productData.cards && productData.estatus >= 2
          "
        >
          <ExhibitorTools
            :size="50"
            :info="{
              brand: productData.brand,
              code: productData.code,
              type: 'product',
              cards: productData.cards,
            }"
            :key="productData.code"
          />
        </div>
      </div>
      <div class="right">
        <div class="moreinfo">
          <h3>
            {{ translations.title_product_details }}
          </h3>
          <div class="moreinfocont">
            <MoreInfoItem
              v-for="(item, key, index) in productData.moreProductInfo"
              :key="index"
              :title="detailsTitles[index]"
              :text="{ text: item }"
              type="product"
              class="info"
            />
          </div>
        </div>
        <div v-if="productData.retail && productData.retail.price">
          <ExhibitorRetail
            :id="productData.id"
            :retail="productData.retail"
            :title="productData.brand + ' ' + productData.title"
            :size="'medium'"
          />
        </div>
      </div>
    </div>
    <div
      class="otherproductcont"
      v-if="productData.otherProducts && productData.otherProducts.length > 0"
    >
      <Title
        background="primary_bg_color"
        type="subtitle"
        :text="
          replaceTranslation({
            type: 'other',
            text: [checkOtherProducts, productData.otherProducts.length],
          })
        "
      />
      <div
        v-for="(product, index) in productData.otherProducts.slice(
          0,
          limit[0].limit
        )"
        :key="index"
        class="otherproductitem"
        @click="linkTo(product.code)"
      >
        <div class="otherproduct">
          <img :src="files + product.image" :alt="product.title" />
        </div>
        <p>
          {{ product.title }}
        </p>
      </div>
      <div v-if="checkLength(productData.otherProducts, 0)" class="buttoncont">
        <button
          class="showmore tertiary_bg_color contrast_color"
          @click="cangeLimit(productData.otherProducts.length, 0)"
        >
          {{
            replaceTranslation({
              type: 'showmore',
              text: limit[0].number,
            })
          }}
        </button>
      </div>
      <div v-if="!limit[0].more">
        <button
          class="showmore tertiary_bg_color contrast_color"
          @click="cangeLimit(6, 0)"
        >
          {{ translations.btn_showless }}
        </button>
      </div>
    </div>
    <!-- <Title background="primary_bg_color" type="subtitle" :text="'Other categories'" />
    <div class="contproduct">
      <div
        v-for="(product, index) in otherCategories.slice(0, limit[1].limit)"
        :key="index"
        @click="linkTo(product.id)"
        class="product"
      >
        <ExhibitorTool
          :tool="{
            text: product.tool.text + ' (' + product.tool.number + ')',
            icon: product.tool.icon,
          }"
          :border="false"
          size="medium"
          class="productitem"
        />
      </div>
      <div v-if="checkLength(otherCategories, 1)" class="buttoncont">
        <button class="showmore">
          Show all <span>{{ limit[1].number }}</span>
        </button>
      </div>
    </div> -->
    <div
      class="downloadcont"
      v-if="productData.downloads && productData.downloads.length > 0"
    >
      <Title
        background="primary_bg_color"
        type="subtitle"
        id="downloads"
        :text="translations.title_product_related"
      />
      <SaveAndDownload
        v-for="(item, index) in productData.downloads"
        :key="index"
        :data="item"
        :index="index"
        :type="'download'"
        :class="defineDownloadclas"
      />
    </div>

    <div class="otherbrands" v-if="actualCategory[0]">
      <Title
        background="tertiary_bg_color"
        type="subtitle"
        :text="
          replaceTranslation({
            type: 'similar',
            text: actualCategory[0].list.length,
          })
        "
      />
      <ul>
        <li
          v-for="(item, index) in actualCategory[0].list.slice(
            0,
            limit[2].limit
          )"
          :key="index"
        >
          <router-link :to="'/exhibitor/' + item.id">
            • <span class="link">{{ item.item }}</span>
          </router-link>
        </li>
      </ul>
      <div v-if="checkLength(actualCategory[0].list, 2)" class="buttoncont">
        <button class="showmore tertiary_bg_color contrast_color">
          {{
            replaceTranslation({
              type: 'similar',
              text: limit[2].number,
            })
          }}
        </button>
      </div>
      <div v-if="!limit[2].more">
        <button
          class="showmore tertiary_bg_color contrast_color"
          @click="cangeLimit(6, 2)"
        >
          {{ translations.btn_showless }}
        </button>
      </div>
    </div>
    <div
      class="brandcategories border_fourth"
      v-if="otherCategories.length > 0"
    >
      <Title
        background="tertiary_bg_color"
        type="subtitle"
        :text="translations.title_brands_related"
      />
      <Category
        v-for="(category, index) in otherCategories.slice(0, limit[3].limit)"
        :key="index"
        :limitList="6"
        :link="true"
        :category="{ list: category.list }"
        :icon="{
          text: category.title,
          icon: category.icon,
          number: category.count - 1,
        }"
        :index="index"
        :decoration="true"
        class="category"
      />
      <div v-if="checkLength(otherCategories, 3)" class="buttoncont">
        <button
          class="showmore tertiary_bg_color contrast_color"
          @click="cangeLimit(otherCategories.length, 3)"
        >
          {{
            replaceTranslation({
              type: 'categories',
              text: limit[3].number,
            })
          }}
        </button>
      </div>
      <div v-if="!limit[3].more">
        <button
          class="showmore tertiary_bg_color contrast_color"
          @click="cangeLimit(6, 3)"
        >
          {{ translations.btn_showless }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import ExhibitorTools from '@/components/ExhibitorTools.vue'
  import ExhibitorTool from '@/components/ExhibitorTool.vue'
  import Category from '@/components/Category.vue'
  import MoreInfoItem from '@/components/MoreInfoItem.vue'
  import ExhibitorRetail from '@/components/ExhibitorRetail.vue'
  import Slider from '@/components/Slider.vue'
  import SaveAndDownload from '@/components/SaveAndDownload.vue'
  import { mapActions, mapState } from 'vuex'
  import router from '../router'

  export default {
    name: 'Product',
    components: {
      Title,
      Category,
      Slider,
      ExhibitorTools,
      ExhibitorTool,
      MoreInfoItem,
      ExhibitorRetail,
      SaveAndDownload,
    },
    data() {
      return {
        actualCategory: [],
        otherCategories: [],
        limit: [
          { limit: 6, number: 0, more: true },
          { limit: 6, number: 0 },
          { limit: 9, number: 0, more: true },
          { limit: 6, number: 0, more: true },
        ],
        detailsTitles: [],
        otherProducts: [
          { id: 'id', title: 'HUAWEI nova 7i', img: 'nova-7i.jpg' },
          { id: 'id', title: 'HUAWEI P40', img: 'hua-p40.jpg' },
          { id: '1', title: 'Mate40 Pro 5G', img: 'hua-p40.jpg' },
          { id: 'id', title: 'HUAWEI Mate 30', img: 'nova-7i.jpg' },
          { id: 'id', title: 'Huawei Enjoy 10', img: 'nova-7i.jpg' },
          { id: 'id', title: 'Huawei P40 pro', img: 'nova-7i.jpg' },
          { id: 'id', title: 'Huawei Y7 pro', img: 'nova-7i.jpg' },
          { id: 'id', title: 'HUAWEI nova 7i', img: 'nova-7i.jpg' },
        ],
      }
    },
    computed: {
      ...mapState(['productData', 'allData', 'files', 'translations']),

      checkOtherProducts() {
        return this.productData.otherProducts.length >= 6
          ? 6
          : this.productData.otherProducts.length
      },
      defineDownloadclas() {
        return this.productData && this.productData.downloads.length == 1
          ? 'oneviewanddownload'
          : 'viewanddownload'
      },
    },
    methods: {
      ...mapActions(['getProducts', 'getProductData', 'resetProduct']),
      linkTo(code) {
        this.getProductData(code)
        router.push('/product/' + code)
      },
      checkLength(data, position) {
        let result = false
        if (data && data.length > this.limit[position].limit) {
          result = true
          this.limit[position].number = data.length - this.limit[position].limit
        }
        return result
      },
      async discard() {
        if (this.allData.categoriesProd) {
          // this.actualCategory = this.checkCategories().filter(
          //   (item) => item.id == this.productData.categoryId
          // )
          this.otherCategories = this.checkCategories().filter((item) => {
            return item.id != this.productData.categoryId
          })
          console.log(this.otherCategories)
          this.otherProducts = this.otherProducts.filter(
            (item) => item.id != this.$route.params.id
          )
          this.checkLength(this.actualCategory[0], 2)
        }
      },
      cangeLimit(num, position) {
        this.limit[position].limit = num
        this.limit[position].more = !this.limit[position].more
      },
      checkCategories() {
        if (this.allData.categoriesProd) {
          return this.allData.categoriesProd.filter((item) => {
            let res = ''
            if (item.list) {
              for (const element of item.list) {
                if (element.code == this.productData.brandCode) {
                  res = item
                  break
                }
              }
            }
            // res = item
            return res
          })
        }
      },
      replaceTranslation(value) {
        let result = ''
        switch (value.type) {
          case 'other':
            result = this.translations.title_other_products
            result = result.replace('{var0}', value.text[0])
            result = result.replace('{var1}', value.text[1])
            break
          case 'showmore':
            result = this.translations.btn_showmore_numeric.replace(
              '{var}',
              value.text
            )
            break
          case 'categories':
            result = this.translations.btn_showmore_numeric_categories.replace(
              '{var}',
              value.text
            )
            break
          case 'similar':
            result = this.translations.title_brands_similar_product.replace(
              '{var}',
              value.text
            )
            break
        }

        return result
      },
      addTranslations() {
        if (this.translations) {
          this.detailsTitles = [
            this.translations.label_info_exhibition,
            this.translations.label_info_market,
            this.translations.label_info_product_categories,
            this.translations.label_info_exhibition_markets,
            this.translations.label_info_country,
            this.translations.label_info_product_type,
            this.translations.label_info_minimum_order,
            this.translations.label_info_production,
            this.translations.label_info_hs,
            this.translations.label_info_recommended,
            this.translations.label_info_lead,
            this.translations.label_info_manufacturer_warranty,
            this.translations.label_info_ean,
            this.translations.label_info_certificates,
            this.translations.label_info_manufacturer,
            this.translations.label_info_downloads,
          ]
        }
      },
    },

    created() {
      this.getProductData(this.$route.params.id)
      this.discard()
      this.addTranslations()
    },
    watch: {
      $route(to, from) {
        if (to.name == 'Product') {
          this.getProductData(this.$route.params.id)
          this.discard()
          this.limit = [
            { limit: 6, number: 0, more: true },
            { limit: 6, number: 0 },
            { limit: 9, number: 0, more: true },
            { limit: 6, number: 0, more: true },
          ]
        }
      },
    },
    beforeUnmount() {
      this.resetProduct()
    },
  }
</script>

<style scoped>
  .contleftright {
    text-align: center;
  }

  .left,
  .right {
    width: 48%;
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .description {
    word-wrap: break-word;
    width: auto;
  }
  .descriptioncont {
    padding: 10px;
  }
  .brandcategories {
    text-align: center;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #303030;
    margin-top: 20px;
  }

  .brandcategories .category {
    height: 250px;
    width: 32%;
    vertical-align: top;
    display: inline-block;
  }
  p {
    text-align: left;
  }
  .downloadcont,
  .contproduct {
    text-align: center;
  }
  .download {
    width: 29%;
    display: inline-block;
    vertical-align: top;
  }
  .otherproductcont {
    text-align: center;
  }

  .otherproductitem {
    display: inline-block;
    width: 33%;
    vertical-align: top;
    margin: 10px 0;
  }
  .imgcont {
    position: relative;
    height: 355px;
  }
  .otherproduct {
    position: relative;
    height: 150px;
  }
  .otherproductitem p {
    text-align: center;
    overflow-wrap: anywhere;
    padding: 0;
  }

  .otherproduct img,
  .imgcont img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  /* .moreinfo .showmore, */
  .contproduct .showmore,
  .otherbrands .showmore {
    margin: 10px 0 25px 0;
  }
  .otherproductcont .buttoncont .showmore {
    margin: 30px 0 30px 0;
  }
  .moreinfo h3 {
    text-align: center;
    font-size: 24px;
    margin: 0 0 20px 0;
  }
  .moreinfocont {
    text-align: center;
    margin-bottom: 20px;
  }
  /* .moreinfo .showmore {
    background-color: #d25252;
  } */
  .product {
    width: 32%;
    display: inline-block;
    vertical-align: top;
  }

  a {
    color: #7a7a7a;
  }
  .otherbrands .link {
    text-decoration: underline;
    margin-left: 5px;
  }
  .otherbrands li {
    width: 33%;
    display: inline-block;
    text-align: left;
  }
  .otherbrands ul {
    padding: 0 25px;
  }
  .buttoncont {
    text-align: center;
  }
  .info {
    display: inline-block;
    width: 33%;
    vertical-align: top;
  }
  .viewanddownload {
    width: 100%;
  }
  .oneviewanddownload {
    width: 100%;
    padding: 0;
  }

  @media screen and (max-width: 930px) {
    .right,
    .left {
      width: 100%;
      padding: 0;
    }
  }

  @media screen and (max-width: 800px) {
    .brandcategories .category {
      width: 49%;
    }
  }
  /* @media screen and (max-width: 650px) {
    .right,
    .left {
      width: 90%;
    }
  } */
  @media screen and (max-width: 550px) {
    /* .right,
    .left {
      width: 100%;
    } */
    .otherproductitem {
      width: 50%;
    }
  }
  @media screen and (max-width: 500px) {
    .brandcategories .category {
      width: 100%;
    }
    .info {
      width: 50%;
    }
  }
  @media screen and (max-width: 400px) {
    .moreinfo h3 {
      font-size: 16px;
    }
  }
</style>
